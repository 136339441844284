const PortRouterHelper = {};

PortRouterHelper.install = function (Vue, options) {

  // todo ideally remove $route and get it globally
  Vue.prototype.$transformParamsForPort = function ($route, routeParams) {
    if ($route.params.port_slug || $route.name === 'port-slug-category_slug') {
      const slug = $route.name === 'port-slug-category_slug' ? $route.params.slug : $route.params.port_slug;
      if (routeParams.name) {
        routeParams.name += '-with-port';
      } else if (routeParams.path) {
        routeParams.path = '/port-' + slug + routeParams.path;
      }
      if (!routeParams.params) {
        routeParams.params = {};
      }
      routeParams.params.port_slug = slug;
    }
    return routeParams;
  };

};

export default PortRouterHelper;
