<template>
  <div class="error">
    <div class="error__page">
      <div class="error__data">
        <img class="error__bg" src="~/assets/images/bg-error.png">
        <svg-icon class="error__binocular-ico" name="binocular-icon"></svg-icon>
        <p class="error__message">{{ $t("catalog-error-message") }}</p>
        <p class="error__text">{{ $t("catalog-error-text") }}</p>
        <button class="error__button button" @click="goToMainPage">{{ $t("catalog-error-button") }}</button>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    layout: 'BaseLayout',
    props: ['error'],
    methods: {
      goToMainPage() {
        this.$router.push(this.$transformParamsForPort(this.$route, { path: '/' }));
      }
    }
  };
</script>

<style scoped>
  .error__page {
    display: flex;
    position: relative;
    padding: 0 24px;
    box-sizing: border-box;
    min-height: calc(100vh - 56px);
    background: linear-gradient(
      206.47deg,
      rgba(34, 32, 31, 0) 9.9%,
      #22201F 40.08%
    );
    overflow: hidden;

    @media (--viewport-tablet) {
      padding: 89px 112px;
      min-height: calc(100vh - 112px);
      background-image: linear-gradient(
        103.71deg,
        #22201F 51.3%,
        rgba(34, 32, 31, 0) 107.43%
      );
    }
  }

  .error__data {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 104px;
    box-sizing: border-box;
    max-width: 413px;

    @media (--viewport-tablet) {
      display: block;
    }
  }

  .error__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    max-height: 70%;

    @media (--viewport-tablet) {
      max-height: 100%;
      max-width: 60%;
    }
  }

  .error__binocular-ico {
    margin-bottom: 40px;
    width: 64px;
    height: 64px;

    @media (--viewport-tablet) {
      margin-bottom: 70px;
    }
  }

  .error__message {
    margin-bottom: 24px;
    font-family: var(--secondary-font);
    font-weight: 600;
    font-size: 32px;
    line-height: 1.12;
    color: #FFFFFF;

    @media (--viewport-tablet) {
      font-size: 48px;
      line-height: 1.04;
    }
  }

  .error__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    color: #FFFFFF;
    margin-bottom: 40px;

    @media (--viewport-tablet) {
      margin-bottom: 56px;
    }
  }

  .error__button {
    display: inline-block;
    min-width: 213px;
  }
</style>

